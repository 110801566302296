<template>
  <div class="highlighted-list">
    <UiHeader
      v-if="header"
      class="highlighted-list__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <ul
      v-if="itemList.length"
      class="highlighted-list__list"
      :class="themeColorClass"
    >
      <li
        v-for="item in itemList"
        :key="item.id"
        class="highlighted-list__item"
      >
        <T3HtmlParser
          v-if="item.name"
          :tag="item.text ? 'h3' : 'div'"
          class="highlighted-list__title"
          :content="item.name"
        />

        <T3HtmlParser
          v-if="item.text"
          class="highlighted-list__text"
          :content="item.text"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesProps } from '~/types'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)
</script>

<style lang="scss">
.highlighted-list {
  &__heading {
    margin-bottom: rem(60px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(48px) rem(24px);
    list-style: none;
    padding: 0;
    margin: 0 auto;

    @include media-query(sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: rem(24px);
    margin: 0 auto;
    padding-left: rem(14px);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: rem(-12px);
      left: 0;
      height: rem(52px);
      width: rem(4px);
      background-color: var(--theme__theme-color);
    }
  }

  &__title {
    margin-bottom: 0;
    font-size: rem(24px);
    font-weight: 700;
  }

  &__text {
    font-size: rem(18px);
  }
}
</style>
